<template>
  <b-card-code title="Feladataim időrendben">

    <!-- table -->
    <vue-good-table
      class="table-fixer"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: false,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            size="sm"
            variant="primary"
            class="btn-tour-next"
            @click="show(props.row.project_id, props.row.type)"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="12"
            />
          </b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  props: {
    rows: {
      type: Array,
    },
  },
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BButton,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Munka ID',
          field: 'work_id',
          filterOptions: {
            enabled: false,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Munka azonosító',
          field: 'work_number',
        },
        {
          label: 'Feladat megnevezése',
          field: 'title',
          filterOptions: {
            enabled: false,
            placeholder: 'Search Email',
          },
        },
        {
          label: 'Határidő',
          field: 'end_date',
          filterOptions: {
            enabled: false,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Prioritás',
          field: 'priority',
          filterOptions: {
            enabled: false,
            placeholder: 'Search Status',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    console.log(this.rows)
  },
  methods: {
    show(id, type) {
      if (type === 'preparatory') {
        this.$router.push({ name: 'monolit.global.todo' })
      } else {
        this.$router.push({ name: 'monolit.global.todo' })
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style scoped>
.table-fixer {
  max-height: 400px;
  overflow: auto;
}

.table-fixer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.table-fixer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(192, 192, 192);
  border-radius: 6px;
}

/* Handle */
.table-fixer::-webkit-scrollbar-thumb {
  background: #2c3e50;
  border-radius: 6px;
}

.table-fixer/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2c3e50;
}

</style>
