<template>
  <div>
    <!-- FELSŐ STATOK -->
    <b-card
      v-if="data"
      no-body
      class="card-statistics pt-1"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media style="cursor:pointer" no-body @click="show(item.type)">
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      v-if="data"
      no-body
      class="card-statistics pt-1"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data2"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
            v-show="checkRole(item.role)"
          >
            <b-media style="cursor:pointer" class="mt-2 mb-2" no-body @click="show2(item.type)">
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      v-if="data"
      no-body
      class="card-statistics pt-1"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data3"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
            v-show="checkRole(item.role)"
          >
            <b-media style="cursor:pointer" class="mt-2 mb-2" no-body @click="show2(item.type)">
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!--<div class="row">
      <div class="col-lg-12">
        <table-list :rows="rows" class="w-100 table-list-box" />
      </div>-->
      <!--<div class="col-lg-4">
        <pie-chart class="w-100" />
      </div>-->
    </div>
  </div>
  <!-- TÁBLA BENNE A LEJÁRÓ -->
</template>

<script>
import {
  BCard, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import TableList from '@/components/dashboard/TableList.vue'
import PieChart from '@/components/dashboard/PieChart.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      data3: [
        {
          icon: 'UserIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Függőben lévő szabadság elbírálás',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.freeday.own',
          role: 'foreman_freeday',
        },
        {
          icon: 'UserIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Függőben lévő szabadság elbírálás',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.freeday.own.leader',
          role: 'leader_freeday',
        },
      ],
      data2: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Rendeléseim',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.order.own',
          role: 'create_order',
        },
        {
          icon: 'ClockIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Haramosan lejáró rendelések',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.order.list.list',
          role: 'delivery_offer',
        },
        {
          icon: 'TruckIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Kiküldött rendelések',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.order.list.list',
          role: 'delivery_offer',
        },
        {
          icon: 'CheckSquareIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Visszaigazolt rendelések',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.order.list.list',
          role: 'delivery_offer',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Beérkezett rendelések',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.order.list.list',
          role: 'delivery_offer',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Visszaigazolásra váró rendelések',
          customClass: 'mb-2 mb-xl-0',
          type: 'monolit.order.list.list',
          role: 'accept_order',
        },
      ],
      data: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '? darab',
          subtitle: 'Feladataim',
          customClass: 'mb-2 mb-xl-0',
          type: 'Feladataim',
        },
        {
          icon: 'ClockIcon',
          color: 'light-warning',
          title: '? darab',
          subtitle: 'Hamarosan lejáró',
          customClass: 'mb-2 mb-xl-0',
          type: 'Hamarosan_lejáró',
        },
        {
          icon: 'AlertCircleIcon',
          color: 'light-danger',
          title: '? darab',
          subtitle: 'Lejárt határidők',
          customClass: 'mb-2 mb-sm-0',
          type: 'Késésben_lévő',
        },
        {
          icon: 'FileTextIcon',
          color: 'light-success',
          title: '? darab',
          subtitle: 'Kiadott feladatok',
          customClass: '',
          type: 'Kiadott',
        },
      ],
      rows: [],
    }
  },
  mounted() {
    axios({
      method: 'get',
      url: 'stat/list',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.data[0].title = `${res.data.basic_stat.given_task} darab`
      this.data[1].title = `${res.data.basic_stat.coming_soon_out_of_date} darab`
      this.data[2].title = `${res.data.basic_stat.out_of_date} darab`
      this.data[3].title = `${res.data.basic_stat.assigned_task} darab`

      this.data2[0].title = `${res.data.order_stat.my_orders} darab`
      this.data2[1].title = `${res.data.order_stat.orders_3_days} darab`
      this.data2[2].title = `${res.data.order_stat.accepted_warehouse} darab`
      this.data2[3].title = `${res.data.order_stat.completed_orders} darab`
      this.data2[4].title = `${res.data.order_stat.accepted_offer} darab`
      this.data2[5].title = `${res.data.order_stat.review_order} darab`

      this.rows = res.data.my_tasks
    })

    axios({
      method: 'get',
      url: 'freeday/list/leader/waiting',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.data3[1].title = `${res.data} darab`
    })

    axios({
      method: 'get',
      url: 'freeday/list/foreman/waiting',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.data3[0].title = `${res.data} darab`
    })
  },
  methods: {
    checkRole(roles) {
      return JSON.parse(localStorage.getItem('user')).role.includes(roles)
    },
    show(type) {
      this.$router.push({ name: 'monolit.global.todo.filter', params: { filter: type } })
    },
    show2(type) {
      this.$router.push({ name: type })
    },
  },
}
</script>
