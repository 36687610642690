<template>
  <b-card title="Feladatok megoszlása">
    <vue-apex-charts
      :options="earningsChart.chartOptions"
      :series="[53, 16, 31]"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const $earningsStrokeColor1 = '#28c76f'
const $earningsStrokeColor2 = '#FF9F43'
const $earningsStrokeColor3 = '#EA595E'
export default {
  components: {
    BCard,
    VueApexCharts,
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Folymatban', 'Késésben', 'Lejárt'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor1, $earningsStrokeColor2, $earningsStrokeColor3],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    color: '#717577',
                    label: 'feladat van még hátra',
                    formatter() {
                      return '50 darab'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
